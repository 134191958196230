exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-investors-js": () => import("./../../../src/pages/about/investors.js" /* webpackChunkName: "component---src-pages-about-investors-js" */),
  "component---src-pages-about-media-js": () => import("./../../../src/pages/about/media.js" /* webpackChunkName: "component---src-pages-about-media-js" */),
  "component---src-pages-about-story-js": () => import("./../../../src/pages/about/story.js" /* webpackChunkName: "component---src-pages-about-story-js" */),
  "component---src-pages-about-team-js": () => import("./../../../src/pages/about/team.js" /* webpackChunkName: "component---src-pages-about-team-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-philosophy-ai-js": () => import("./../../../src/pages/philosophy/ai.js" /* webpackChunkName: "component---src-pages-philosophy-ai-js" */),
  "component---src-pages-philosophy-business-model-js": () => import("./../../../src/pages/philosophy/business-model.js" /* webpackChunkName: "component---src-pages-philosophy-business-model-js" */),
  "component---src-pages-philosophy-corporate-social-responsiblity-js": () => import("./../../../src/pages/philosophy/corporate-social-responsiblity.js" /* webpackChunkName: "component---src-pages-philosophy-corporate-social-responsiblity-js" */),
  "component---src-pages-philosophy-purpose-js": () => import("./../../../src/pages/philosophy/purpose.js" /* webpackChunkName: "component---src-pages-philosophy-purpose-js" */),
  "component---src-pages-solutions-asset-lifecycle-management-js": () => import("./../../../src/pages/solutions/asset-lifecycle-management.js" /* webpackChunkName: "component---src-pages-solutions-asset-lifecycle-management-js" */),
  "component---src-pages-solutions-financing-solutions-js": () => import("./../../../src/pages/solutions/financing-solutions.js" /* webpackChunkName: "component---src-pages-solutions-financing-solutions-js" */),
  "component---src-pages-solutions-index-js": () => import("./../../../src/pages/solutions/index.js" /* webpackChunkName: "component---src-pages-solutions-index-js" */),
  "component---src-pages-solutions-procurement-optimization-js": () => import("./../../../src/pages/solutions/procurement-optimization.js" /* webpackChunkName: "component---src-pages-solutions-procurement-optimization-js" */)
}

